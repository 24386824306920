.pagination-container.pagination li:first-of-type {
  background: url(../../icons/LeftArrow.svg) no-repeat center;
  text-indent: -99999px;
}

.pagination-container.pagination li:last-of-type {
  background: url(../../icons/RightArrow.svg) no-repeat center;
  text-indent: -99999px;
}

.pagination-container.pagination li > a.disabled {
  display: none;
}