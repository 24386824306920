.category-selector > nav a {
  height: 100%;
  text-decoration: none;
  color: var(--wl-text-color-four);
}

.category-selector > nav figure {
  margin: 0;
}

.category-selector > nav .image-container {
  position: relative;
}

.category-selector > nav .image-container::after {
  display: block;
  content: "";
  padding-bottom: 100%;
}

.category-selector > nav img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-selector > nav > ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.category-selector > nav > ul > li {
  width: 23%;
  list-style: none;
  margin-bottom: 1rem;
  background: var(--wl-brand-color-one);
  box-shadow: 0 3px 10px 0px rgba(125, 125, 125, 0.7);
  -moz-box-shadow: 0 3px 10px 0px rgba(125, 125, 125, 0.7);
  -webkit-box-shadow: 0 3px 10px 0px rgba(125, 125, 125, 0.7);
}

.category-selector > nav h1 {
  padding-left: 1rem;
  margin: 16px 0;
}

.category-selector > nav p.description {
  font-size: var(--wl-font-size-large);
  font-weight: 500;
  line-height: 1.4em;
  padding: 1rem;
  padding-top: 0;
  margin: 0;
  margin-bottom: 2rem;
  height: 60px;
}

.category-selector > nav p.category {
  font-size: var(--wl-font-size-h2);
  font-weight: bold;
  margin: 16px 0;
  padding-left: 1rem;
}

@media (max-width: 968px) {
  .category-selector > nav > ul > li {
    width: 47%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 500px) {
  .category-selector > nav > ul > li {
    width: 100%;
  }
}
