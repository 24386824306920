.blog-article {
  max-width: var(--wl-max-header-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.blog-article > .breadcrumb {
  margin: 24px 0;
  width: 80%;
  max-width: 1480px;
  padding: 0;
}

.blog-article > .body {
  width: 80%;
  max-width: 1480px;
  display: flex;
}

.blog-article > .body .author-line {
  display: flex;
}

.blog-article > .body .author-line span {
  font-weight: bold;
}

.blog-article > .body .author-line .author-avatar {
  width: 64px;
  height: 64px;
  min-width: 64px;
}

.blog-article > .body .author-line .author-avatar img {
  border-radius: 50%;
}

.blog-article > .body .author-line .written-by {
  align-self: center;
  margin-left: 18px;
}

.blog-article > .body .author-line .written-by .published-on {
  font-size: var(--wl-font-size-h4);
}

.blog-article > .body article {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 1.6em;
}

.blog-article > .body article > * {
  order: 2;
}

.blog-article > .body article h1 {
  font-size: var(--wl-font-size-h1);
  line-height: 39px;
  margin: 0 0 29px 0;
}

.blog-article > .body article p {
  font-size: 1rem;
  color: var(--wl-text-color-fifteen);
}

.blog-article > .body article a {
  color: var(--wl-text-color-link);
}

.blog-article > .body .article-meta {
  margin: 16px 0 0 0;
  font-size: var(--wl-font-size-h3);
  line-height: 1.26em;
}

.blog-article > .body .article-meta > div {
  margin: 10px 3% 10px 0;
}

.blog-article > .body .article-meta span {
  margin: 10px 0;
  font-weight: bold;
}

.blog-article > .body .article-content > div {
  display: flex;
  flex-direction: column;
}

.blog-article > .body .article-content > div > img {
  margin-bottom: 30px;
}

.blog-article > .body .article-content img {
  height: auto;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}

.blog-article > .body .article-content iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.blog-article > .body .ads-column {
  display: flex;
  flex-direction: column;
  margin-left: 3%;
}

.blog-article > .body .share-buttons-sticky-container {
  height: 0;
  position: sticky;
  top: 215px;
  order: 1;
  margin-left: -12.5%;
}

.blog-article > .body .share-buttons-container {
  width: 30px;
  height: 330px;
}

.blog-article > .body .author-bio {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding: 15px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  font-size: var(--wl-font-size-h3);
}

.blog-article > .body .author-bio p {
  margin-bottom: 10px;
}

.blog-article > .body .related-articles {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.blog-article > .body .related-articles h3 {
  margin: 36px 0;
  font-size: var(--wl-font-size-xx-large);
}

.blog-article > .body .articles-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.blog-article > .body .articles-container article {
  max-width: 30%;
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  margin-bottom: 25px;
  word-wrap: break-word;
}

.blog-article > .body .articles-container article a {
  color: var(--wl-text-color-one);
}

.blog-article > .body .articles-container article .category {
  margin-bottom: 17px;
}

.blog-article > .body .articles-container article .category span {
  font-weight: bold;
}

.blog-article > .body .articles-container article .title {
  font-weight: bold;
  margin-bottom: 32px;
}

.blog-article > .body .articles-container article .image-container {
  position: relative;
  margin-bottom: 24px;
}

.blog-article > .body .articles-container article .image-container::after {
  display: block;
  content: "";
  padding-bottom: 66%;
}

.blog-article > .body .articles-container article img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-article > .category-selector-container {
  width: 80%;
  max-width: var(--wl-max-content-width);
  margin-top: 55px;
}

@media (min-width: 1200px) {
  .blog-article > .body .article-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .blog-article > .body .article-meta .category {
    text-align: end;
  }
}

.blog-article .ad {
  display: flex;
  justify-content: center;
  width: 300px;
}

.blog-article .ad-728x90 {
  max-width: 728px;
  min-width: 728px;
  overflow: hidden;
}

/* --wl-max-header-width = 1450px */
@media (max-width: 1440px) {
  .blog-article > .body .share-buttons-sticky-container {
    margin-left: -7vw;
  }
}

@media (min-width: 968px) {
  .blog-article > .body .articles-container article:nth-child(3n + 3) {
    margin-right: 0;
  }
}

@media (max-width: 968px) {
  .blog-article > .body .ads-column {
    display: none;
  }

  .blog-article > .body .articles-container {
    justify-content: space-between;
  }

  .blog-article > .body .articles-container article {
    max-width: 47%;
    width: 47%;
    margin-right: 0;
  }

  .blog-article > .body .articles-container .tablet-hidden {
    display: none;
  }

  .blog-article > .body .share-buttons-sticky-container {
    height: fit-content;
    position: relative;
    align-self: flex-end;
    top: 0;
    order: 2;
  }

  .blog-article > .body .share-buttons-container {
    width: 250px;
    height: 50px;
  }
}

@media (max-width: 500px) {
  .blog-article > .body .articles-container article {
    max-width: 100%;
    width: 100%;
  }

  .blog-article > .body .articles-container article .excerpt,
  .blog-article > .body .articles-container .mobile-hidden {
    display: none;
  }

  .blog-article > .body .articles-container article .image-container::after {
    padding-bottom: 36%;
  }
}

@media screen and (min-width: 768px) {
  .blog-article .ad {
    margin-bottom: 8px;
  }
}
