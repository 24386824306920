.blog-navbar {
  box-shadow: 0 2px 10px 0px var(--wl-box-shadow-seven);
  font-size: 20px;
  font-weight: 500;
  -moz-box-shadow: 0 2px 10px 0px var(--wl-box-shadow-seven);
  -webkit-box-shadow: 0 2px 10px 0px var(--wl-box-shadow-seven);
  width: 100%;
}

.blog-navbar > nav {
  height: auto;
}

.blog-navbar > nav > ul {
  display: flex;
  justify-content: space-between;
  padding-inline-start: 0;
  padding: 0;
  margin: 0;
}

.blog-navbar > nav li {
  list-style: none;
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.blog-navbar > nav a {
  color: var(--wl-text-color-default);
  display: block;
  height: 100%;
  text-decoration: none;
  font-size: var(--wl-font-size-x-large);
  line-height: var(--wl-line-height-xx-large);
}

.blog-navbar > nav > ul > li {
  list-style: none;
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .blog-navbar.scroll {
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }

  .blog-navbar.scroll::-webkit-scrollbar {
    display: none;
  }

  .blog-navbar > nav a {
    line-height: var(--wl-line-height-small);
  }

  .blog-navbar > nav,
  .blog-navbar > nav > ul {
    height: 50px;
  }

  .blog-navbar > nav > ul > li {
    list-style: none;
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 500px) {
  .blog-navbar > nav > ul > li {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  .blog-navbar > nav a {
    font-size: var(--wl-font-size-medium);
    font-weight: normal;
    line-height: var(--wl-line-height-x-large);
  }
}

@media (max-width: 370px) {
  .blog-navbar > nav > ul {
    padding-inline-start: 0;
  }
}
