.blog-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--wl-max-header-width);
  margin: 0 auto;
}

.blog-home > .breadcrumb {
  margin: 24px 0;
  width: 80%;
  max-width: var(--wl-max-content-width);
  padding: 0;
}

.blog-home > .body {
  width: 80%;
  max-width: var(--wl-max-content-width);
}

.blog-home > .body figure {
  width: 100%;
  margin: 0;
  color: var(--wl-text-color-sixteen);
  font-weight: 600;
}

.blog-home > .body figure .image-container {
  position: relative;
}

.blog-home > .body figure .image-container::after {
  display: block;
  content: "";
  padding-bottom: 30%;
}

.blog-home > .body figure img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-home > .body figure h1 {
  position: absolute;
  bottom: 0;
  left: 10px;
  font-size: 12vw;
  display: inline-table;
  margin: 0;
  line-height: 0.7;
}

.blog-home > .body > .content {
  margin-top: 48px;
  display: flex;
  background: inherit;
  justify-content: center;
}

.blog-home > .body > .content .spinner-container {
  height: 100px;
  width: 100px;
  position: absolute;
  background-color: transparent;
  margin-top: 40px;
}

.blog-home > .body > .content.loading .articles-container,
.blog-home > .body > .content.loading .ads-column {
  visibility: hidden;
}

.blog-home > .body .articles-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
}

.blog-home > .body .articles-container article {
  width: 31%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-right: 3.5%;
  height: fit-content;
  word-wrap: break-word;
}

.blog-home > .body .articles-container article a {
  color: var(--wl-text-color-one);
}

.blog-home > .body .articles-container article h2 {
  overflow: hidden;
  line-height: 1.24em;
  margin: 32px 0 10px 0;
}

.blog-home > .body .articles-container article .image-container {
  position: relative;
}

.blog-home > .body .articles-container article .image-container::after {
  display: block;
  content: "";
  padding-bottom: 66%;
}

.blog-home > .body .articles-container article img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-home > .body .ads-column {
  display: flex;
  flex-direction: column;
  margin-left: 3%;
}

.blog-home > .category-selector-container {
  width: 80%;
  max-width: var(--wl-max-content-width);
  margin-top: 55px;
}

.blog-home .ad {
  display: flex;
  justify-content: center;
  width: auto;
}

.blog-home .ad-728x90 {
  max-width: 728px;
  min-width: 728px;
  overflow: hidden;
}

@media (min-width: 968px) {
  .blog-home > .body .articles-container article:nth-child(3n + 3) {
    margin-right: 0;
  }
}

@media (max-width: 968px) {
  .blog-home > .body .articles-container {
    justify-content: space-between;
  }

  .blog-home > .body .articles-container article {
    width: 48.5%;
    margin-right: 0;
  }

  .blog-home > .body .articles-container article.tablet-hidden,
  .blog-home > .body .ads-column {
    display: none;
  }
}

@media (max-width: 500px) {
  .blog-home > .body .articles-container article {
    width: 100%;
  }

  .blog-home > .body .articles-container article.mobile-hidden {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .blog-home .ad {
    margin-bottom: 8px;
  }
}
